import React, { useState, createContext, useContext, useEffect } from 'react'
import { getListRank, getMemberRanks } from '../actions/memberInfo'
import {
    getListAvailableRanks,
    checkHadCreditCard, getSelectionState
} from '../functions'
import { LcStorage } from '../../utils/storage'
import { ACCOUNT_NAME, PAST_RANKS } from '../../constants'

export const RankContext = createContext()

export const useRank = () => useContext(RankContext)

const userDefaultData = {
    accountId: "000000000",
    accountName: '',
    rankId: '',
    rankName: '',
    startDate: null,
    expDate: null,
    differenceGold: null,
    differenceSilver: null,
    totalPurchasePrice: null
}

const paymentDefaultData = {
    point: false,
    creditCard: {
        // registered: checkHadCreditCard(),
        usable: false
    },
    payPay: false
}

const RankContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [userInfo, setUserInfo] = useState(userDefaultData)
    const [listRanks, setListRanks] = useState([])
    // Rank selected in list options
    const selectionState = getSelectionState()
    const [selectedRank, setSelectedRank] = useState(
        selectionState?.rank
    )
    const [openMdRejoinPrevRank, setOpenMdRejoinPrevRank] = useState(false)
    const [pointBalance, setPointBalance] = useState(
        selectionState?.points ?? 0
    )
    const [paymentMethods, setPaymentMethods] = useState(
        selectionState?.method ?? paymentDefaultData
    )

    useEffect(() => {
        return () => setErrorMsg('')
    }, [])

    const resetContext = () => {
        setUserInfo(userDefaultData)
        setSelectedRank(null)
        // setListRanks([])
        setPointBalance(0)
        setPaymentMethods(paymentDefaultData)
    }

    // Fetch and filter list ranks
    const fetchListRanks = (currentRankId = '') => {
        setIsLoading(true)
        const handleDataRpGetListRank = res => {
            if (res?.status === 200 && res?.data?.success) {
                const { account_rank_group_list: listRanksAPI } = res.data
                setErrorMsg('')
                setListRanks(listRanksAPI)
            }
            else
                setErrorMsg(res?.data?.message)

            setIsLoading(false)
        }

        getListRank(handleDataRpGetListRank);
    }

    const fetchUserInfo = () => {
        const handleDataRpGetRank = (res) => {
          if (res?.status === 200 && res?.data?.success) {
            const { data: resData } = res;
    
            if (resData?.past_rank_list)
              LcStorage.setLocalJson(PAST_RANKS, resData?.past_rank_list);
    
            LcStorage.setLocalJson(ACCOUNT_NAME, resData?.account_name || "");
    
            setErrorMsg("");
            setUserInfo({
              ...userInfo,
              accountId: resData?.account_id || "",
              accountName: resData?.account_name || "",
              rankId: resData?.rank_id,
              rankName: resData?.rank_name,
              startDate: resData?.rank_start_date,
              expDate: resData?.rank_expiration_date,
              differenceGold: resData?.difference_to_rank1,
              differenceSilver: resData?.difference_to_rank2,
              totalPurchasePrice: resData?.total_purchase_price,
            });
          } else {
            setErrorMsg(res?.data?.message);
            LcStorage.setLocalJson(ACCOUNT_NAME, "");
          }
        };
    
        getMemberRanks(handleDataRpGetRank);
      };

    return (
        <RankContext.Provider
            value={{
                userInfo, setUserInfo,
                isLoading, setIsLoading,
                errorMsg, setErrorMsg,
                selectedRank, setSelectedRank,
                listRanks, setListRanks,
                resetContext,
                fetchListRanks,
                fetchUserInfo,
                openMdRejoinPrevRank, setOpenMdRejoinPrevRank,
                pointBalance, setPointBalance,
                paymentMethods, setPaymentMethods
            }}
        >
            {children}
        </RankContext.Provider>
    )
}

export default RankContextProvider
